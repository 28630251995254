import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HRIS from "./images/hr123.jpeg";
import { Link } from "react-router-dom";
import "./Hris.css";
import Footer from "./FooterF";

const Hris = () => {
  return (
    <div>
      {/* <div className="image-container">
        <div className="position-relative">
          <img src={HRIS} className="img-fluid" alt="Payroll Services" />
          <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"></div>
          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-start p-3 text-light "></div>
          <span className="el6-hdimg2">                                 Human Resources Information System</span>
        </div>
        <Link to="/contactus">
          <div
            className="d-flex justify-content-left"
            style={{ textAlign: "left" }}
          >
            <button className="btn btn-outline-light el6-btnpr2">
              Get in touch
            </button>
          </div>
        </Link>
      </div> */}
      <div className="div1card_el7">
        <div className="image-container img_el7">
          <img src={HRIS} className="img-fluid" alt="Technology" />
          <div className="overlay_el7"></div>
          <div className="txt_el7" style={{ textAlign: "left" }}>
            <h1 className="text-left txt_head_el7">
              Human Resources Information System - HRIS
            </h1>
            {/* <p className='text-left text_slogan_el7'>Pioneering Tomorrow's Solutions Today</p> */}
            <Link to="/contactus" style={{ textDecoration: "none" }}>
              <div
                className="d-flex justify-content-left"
                style={{ textAlign: "left" }}
              >
                <button className="btn btn-outline-light btn_el7">
                  Get in touch
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row g-3 ">
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 xpertise-content">
                  At Enormous Corporate Solutions, we understand the
                  transformative impact that implementing a cloud-based Human
                  Resources Information System (HRIS) can have on your HR
                  strategy. By adopting a cloud HRIS, you can unlock a range of
                  benefits that elevate your HR practices to new heights.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 xpertise-content">
                  One of the key advantages of a cloud HRIS is the enhanced user
                  experience it offers. Our system provides a user-friendly
                  interface that simplifies HR tasks and processes, ensuring
                  that HR professionals, managers, and employees can easily
                  navigate and interact with the system. This intuitive user
                  experience translates into increased efficiency, productivity,
                  and employee satisfaction.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 xpertise-content">
                  Our cloud HRIS also offers comprehensive mobile functionality,
                  enabling HR-related tasks and information to be accessed
                  anytime, anywhere. This mobile capability empowers both
                  managers and employees to perform HR functions on-the-go,
                  whether it's submitting leave requests, accessing employee
                  data, or reviewing performance evaluations. With our solution,
                  you can create a more agile and connected workforce that can
                  seamlessly engage with HR processes anytime and from any
                  device. Flexibility is another key aspect of our cloud HRIS.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 xpertise-content">
                  We understand that businesses evolve and grow, and our system
                  is designed to easily scale and adapt to meet your evolving HR
                  requirements. Whether it's adding new modules, integrating
                  with other systems, or accommodating changes in company
                  policies and procedures, our cloud HRIS provides the necessary
                  flexibility to support your dynamic HR strategy effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 xpertise-content">
                  By implementing our cloud HRIS, you also gain access to the
                  latest innovations and technological advancements in HR. From
                  advanced analytics and reporting capabilities to artificial
                  intelligence and machine learning functionalities, our
                  solution empowers you to leverage cutting-edge tools that
                  provide valuable insights, facilitate data-driven
                  decision-making, and enhance overall HR processes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 xpertise-content">
                  In summary, our cloud HRIS empowers organizations to
                  revolutionize their HR strategies by delivering an improved
                  user experience, mobile functionality, and the flexibility to
                  adapt to changing business demands. By embracing the latest HR
                  innovations, you can better support your managers and
                  employees, drive productivity, and optimize your HR operations
                  for long-term success.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 xpertise-content">
                  At Enormous Corporate Solutions, we are committed to providing
                  you with a powerful and transformative cloud HRIS solution
                  that helps you achieve your HR goals.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 xpertise-content">
                  Enormous Corporate Solutions offers a comprehensive,
                  all-in-one HRIS system that serves as a one-stop solution for
                  all your HR needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row g-3">
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 mb-4 el6-head2 xpertise-content">
                  Our HRIS system provides a wide range of features and
                  functionalities, including:
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="">
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong>Employee Database:</strong> Centralize and manage employee
              information securely within our HRIS system
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong> Payroll:</strong> Streamline payroll processing,
              including accurate computation of wages, tax deductions, and
              timely salary disbursements
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong>Time & Attendance:</strong> Track and manage employee
              attendance and work hours efficiently.
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong> Leave Management: </strong> Automate leave requests,
              approvals, and tracking, ensuring seamless management of employee
              time off.
            </li>
          </ul>
        </div>
      </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong> Reimbursement: </strong> Simplify expense reimbursement
              processes, allowing employees to submit claims and enabling
              efficient reimbursements.
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong>TDS & Tax Planner:</strong> Effectively manage tax-related
              information and plan for tax compliance with our integrated TDS
              and tax planner module
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong>Loans & Advances: </strong>Facilitate loans and advances
              management, including loan requests, approvals, and tracking
              repayments.
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong>Travel: </strong>Streamline travel management processes,
              including travel requests, approvals, and expense tracking.
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong>Recruitment:</strong> Enhance the recruitment process with
              our recruitment module, which includes candidate sourcing,
              applicant tracking, and interview scheduling.
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong>Performance: </strong>Enable performance evaluation and
              management, including setting goals, conducting appraisals, and
              tracking performance metrics.
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong>Training:</strong> Manage employee training programs,
              including training needs assessment, scheduling, and tracking.
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong> Time Sheet:</strong> Streamline time tracking and
              reporting for project-based or hourly employees.
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong>Mobile App: </strong>Provide employees with a mobile app
              for convenient access to HRIS features, such as leave requests,
              attendance tracking, and accessing employee information.
            </li>
          </ul>
        </div>
      </div>
      <div className="why-choose_el64 ymlr-el64">
        <div className="expertise-content">
          <ul>
            <li>
              <strong>Employee Self-Service:</strong> Empower employees with
              self-service capabilities, allowing them to access and update
              personal information, view payslips, request leaves, and more.
            </li>
          </ul>
        </div>
      </div>{" "} */}

      <div className="container mt-4">
        <div className="row xpertise-content">
        <div className="col-md-6  col-lg-4  mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Employee Database:</strong> Centralize and manage
                    employee information securely within our HRIS system.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        <div className="col-md-6  col-lg-4 mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Performance:</strong> Enable performance evaluation
                    & management, tracking performance metrics.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6  col-lg-4 mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Time Sheet:</strong> Streamline time tracking and
                    reporting for project-based or hourly employees.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Payroll:</strong> Streamline payroll processing by ensuring accurate wage calculations, tax deductions, and timely salary disbursement.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Loans & Advances:</strong> Facilitate loans and
                    advances management, including loan requests, approvals, and
                    tracking repayments.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Reimbursement:</strong> Simplify expense
                    reimbursement processes, allowing employees to submit claims
                    and receive payments efficiently.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6  col-lg-4 mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>TDS & Tax Planner:</strong> Effectively manage tax
                    information and plan for compliance with our TDS and tax
                    module.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="col-md-6  col-lg-4  mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Travel:</strong> Streamline travel management,
                    including travel requests, approvals, and expense tracking.
                  </li>
                </ul>
              </div>
            </div>
          </div>
         
          <div className="col-md-6  col-lg-4  mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Leave Management:</strong> Automate leave requests,
                    approvals, tracking, ensuring seamless management of
                    employee time off.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6  col-lg-4 mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Recruitment:</strong> Improve recruitment with our
                    module, featuring candidate sourcing, applicant tracking,
                    and interview scheduling.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6  col-lg-4  mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Training:</strong> Manage employee training
                    programs, including training needs assessment, scheduling,
                    and tracking.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6  col-lg-4  mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue el6-ruc1">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Time & Attendance:</strong> Efficiently track and
                    manage employee attendance and work hours, ensuring accuracy
                    and reporting.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="col-6 mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Mobile App:</strong> Provide employees with a mobile
                    app for convenient access to HRIS features, such as leave
                    requests, attendance tracking, and accessing employee
                    information.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-6 mb-4">
            <div className="why-choose_el641 ymlr-el64 clr-blue">
              <div className="expertise-content">
                <ul>
                  <li>
                    <strong>Employee Self-Service:</strong> Empower employees
                    with self-service capabilities, allowing to access, update
                    personal information, view payslips, request leaves & more.
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          <div class="container">
  <div class="row">
    <div class="col-md-6 mb-4">
      <div class="why-choose_el641 ymlr-el64 clr-blue el6-ruc12">
        <div class="expertise-content">
          <ul>
            <li>
              <strong>Mobile App:</strong> Provide employees with a mobile
              app for convenient access to HRIS features, such as leave
              requests, attendance tracking, and accessing employee
              information.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-4">
      <div class="why-choose_el641 ymlr-el64 clr-blue el6-ruc12">
        <div class="expertise-content">
          <ul>
            <li>
              <strong>Employee Self-Service:</strong> Empower employees
              with self-service capabilities, allowing them to access, update
              personal information, view payslips, request leaves & more.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

          <div className="container mt-4">
            <div className="row g-3">
              <div className="col-12 mb-1 d-flex">
                <div className=" p-1 flex-fill">
                  <div className="card-body text-center">
                    <p className="card-text el6-txt2 mb-4 xpertise-content">
                      With Enormous Corporate Solutions's HRIS system, you have
                      a comprehensive solution that integrates all these
                      features and functionalities, streamlining your HR
                      operations and promoting efficiency and accuracy across
                      the board. Our HRIS system is designed to meet the diverse
                      needs of businesses, offering a robust platform to manage
                      and optimize your HR processes effectively.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Hris;
