import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Payment.css";
import { Link } from "react-router-dom";

import permanet from "./Permanet.jpeg"
import pmt from "./pmt1.jpg";
import Footer from "./FooterF";

export default function Payment() {
  return (
    <div>
      <div className="div1card_el7">
        <div className="image-container img_el7">
          <img src={permanet} className="img-fluid" alt="Technology" />
          <div className="overlay_el7"></div>
          <div className="txt_el7" style={{ textAlign: "left" }}>
            {/* <h1 className="text-down txt_head_el7">PERMANENT /<br></br>CONTRACT STAFFING</h1> */}
            <h1 className="text-down txt_head_el7">PERMANENT /<br></br>CONTRACT STAFFING</h1> 

            <Link to="/contactus" style={{ textDecoration: "none" }}>
              <div
                className="d-flex justify-content-left"
                style={{ textAlign: "left" }}>
           <button className="btn btn-outline-light btn_el7">
                  Get in touch
                </button>
              </div>
            </Link>
          </div>
        </div>
        <div className="mlr_el7">
          <p className="intro_el7" style={{ marginBottom: "20px" }}>
            Enormous Corporate Solutions, excels in delivering innovative
            permanent & contract staffing solutions to enhance the productivity
            of client organizations. With a forward-thinking approach, Enormous
            Corporate Solutions has emerged as one of the fastest-growing
            staffing agencies, offering functional and numerical flexibility to
            empower clients with permanent & contractual staffing.
          </p>
        </div>
 
        <div>
          <h5 className="card-title mb-4 el6-head2 text-center ">
          PERMANENT STAFFING </h5>
         <div className="mlr_el7">
            <p className="intro_el7" style={{ marginBottom: "0px" }}>
            We adhere to a rigorous total quality management program, integrating a thorough recruitment and selection process. 
            Our approach includes detailed assignment briefings, behavioral interviews, and meticulous reference checks to ensure that only top-tier
             candidates are recommended. Led by experienced recruitment consultants, 
            our process allows you to focus on your business goals, knowing that your staffing needs are expertly managed. At Enormous Corporate Solutions, 
            we follow a systematic approach to deliver effective permanent staffing solutions for our clients. This process involves the following key stages:
            </p>
          </div>
                  <div className="why-choose_el64 ymlr-el64">
            <div className="expertise-content">
              <p>
                <strong>Gaining an Insight into Your Requirements: </strong>
                We begin by gaining a comprehensive understanding of your
                staffing requirements. Our team engages in detailed discussions
                to grasp your organization's culture, values, and specific job
                requirements. This allows us to align our search with your needs
                and find the most suitable candidates.
              </p>
              <p>
                <strong>Crafting an Effective Strategy:</strong> Based on the
                gathered information, we develop a strategic plan tailored to
                your organization's staffing goals. This includes determining
                the appropriate sourcing channels, utilizing our extensive
                network, and leveraging cutting-edge recruitment tools and
                techniques.
              </p>
              <p>
                <strong>Identifying and Selecting the Finest Talent: </strong>
                Our experienced recruiters meticulously review applications,
                conduct interviews, and assess candidate qualifications against
                your job requirements. We shortlist the most exceptional talent
                who demonstrate the skills, experience, and cultural fit that
                align with your organization's needs.
              </p>
              <p>
                <strong>
                  Finalizing the Job Offer and Facilitating Onboarding: </strong>
            Once the shortlisting process is complete, we work closely with
                you to finalize the job offer for the selected candidate. We
                assist in negotiating terms and conditions, ensuring a mutually
                beneficial agreement. Additionally, we provide support during
                the onboarding process, \ facilitating a smooth transition for
                the new employee into your organization.
              </p>
            </div>
          </div>
      
            <div className="mlr_el7">
      <h5 className="card-title mb-4 el6-head2 text-center ">
      CONTRACT STAFFING </h5>

            <p className="intro_el7" style={{ marginBottom: "0px" }}>
            Enormous Corporate Solutions excels in providing flexible contract staffing solutions that adapt to your organization's needs, whether 
            for seasonal demands or special projects. We offer high-quality, 
            skilled contractors across various industries, ensuring that each one is meticulously screened to match your specific requirements.
            </p>
          </div>
          <div className="mlr_el7">
            <p className="intro_el7" style={{ marginBottom: "20px" }}>
            Our contract staffing services allow businesses to efficiently scale their workforce, manage hiring and training costs, and reduce HR overhead. 
            By partnering with Enormous Corporate Solutions for contract staffing needs,
             organizations can optimize their workforce and access top talent across various industries, empowering them to achieve their goals with confidence.
            </p>
          </div>

       </div>
<div className="text-center rounded-start">
  <img
    src={pmt}
    alt="Technology"
    className="img-responsive rounded-3"
  />
</div>
        <div >
         </div>
         <div className="mlr_el7">
        <h5 className="card-title mb-4 el6-head2 text-center ">EXTENDED CONTRACT STAFFING SERVICES</h5>
        <p className="intro_el7 text-center" style={{ marginBottom: "0px" }}>
        At Enormous Corporate Solutions, we offer Extended Staffing Services to strengthen and enhance the productivity of organizations.
      </p>
      {/* <p className="head_r  ">Employee Skill Assessment </p> */}

      <div  >

<p className="intro_el7 card_ra" style={{ marginBottom: "25px" }}>
<p style={{fontSize:'25px'}}  className="text-center"><b>Employee Skill Assessment </b></p>
<b > <i >Unlock Your Team's Potential</i></b>
<p>
Evaluate and enhance your workforce's capabilities with precision. Our comprehensive Employee Skill Assessment services ensure that 
your employees are aligned with their roles and equipped to excel. At Enormous Corporate Solutions, we conduct in-depth assessments to:
<ul><li>Identify strengths and areas for improvement.</li>
<li>Monitor alignment with job expectations.</li>
<li>Drive targeted development initiatives.</li>
</ul>
</p>
   Empower your team to reach new heights and achieve excellence in their roles with our expert assessments.
   
   </p>
   </div> 


   <div >

<p className="intro_el7 card_ra" style={{ marginBottom: "25px" }}>
<p style={{fontSize:'25px'}}  className="text-center"><b>Employee Skill Enhancement  </b></p>
<b > <i >Elevate Skills, Boost Engagement</i></b>
<p>
Transform your organization with our tailored Employee Skill Enhancement programs. Designed to address specific skill gaps and foster growth, our initiatives offer:
<ul><li>Improved employee performance and productivity.</li>
<li>Enhanced job satisfaction and motivation.</li>
<li>Increased competency to adapt to industry changes.</li>
<li>Strengthened organizational talent pool.</li>
</ul>
</p>
   Unlock the full potential of your team and drive success with continuous skill development.
   
   </p>
   </div> 
<div >

<p className="intro_el7 card_ra" style={{ marginBottom: "25px" }}>
<p style={{fontSize:'25px'}}  className="text-center"><b> Performance Analytics  </b></p>
<b > <i >Data-Driven Insights for Success</i></b>
<p>
Harness the power of data to elevate your workplace. Our Performance Analytics services provide deep insights into key metrics such as attrition rates, benefits utilization, 
and engagement levels. With our analytics:
<ul><li>Understand employee behaviors and trends.</li>
<li>Make informed decisions to improve the work environment.</li>
<li>Implement effective strategies to boost engagement.</li>
</ul>
</p>
   Drive success with actionable insights and build a thriving workplace that attracts and retains top talent.
    </p>
   </div> 
 <div >

<p className="intro_el7 card_ra" style={{ marginBottom: "25px" }}>
<p style={{fontSize:'25px'}}  className="text-center"><b> Retention Strategies </b></p>
<b > <i >Create a Supportive and Engaging Work Environment</i></b>
<p>
Minimize turnover and enhance employee satisfaction with our tailored Retention Strategies. At Enormous Corporate Solutions, we help you develop effective approaches to:
<ul><li>Lower turnover rates and improve organizational stability.</li>
<li>Reduce hiring costs and recruitment challenges.</li>
<li>Increase employee productivity and satisfaction.</li>
<li>Foster strong work relationships and reduce stress.</li>
</ul>
</p>
 Build a positive, supportive workplace that promotes long-term success and keeps your team motivated and engaged.
   
   </p>
   </div> 
     

 <div>
 <p  className="intro_el7 " style={{ marginBottom: "25px" }} > 
 At Enormous Corporate Solutions, we recognize the importance of retention strategies and assist organizations in developing tailored approaches to minimize turnover, 
 enhance employee satisfaction, and create a positive and supportive work environment that promotes long-term success.
</p>
   
   </div>
     </div>
    </div>
    <Footer  />
    </div>
    
  );
}
