import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PayrollServices.css";
// import payrolls from "./payrolls2.jpg";
import payrolls from "./images/payrolls2.jpg";
import { Link } from "react-router-dom";
import Footer from "./FooterF";

const PayrollServices = () => {
  return (
    <div>
      <div className="image-container">
        <div className="position-relative">
          <img src={payrolls} className="img-fluid" alt="Payroll Services" />
          <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"></div>
          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-start p-3 text-light "></div>
          <span className="el6-hdimg">Payroll Services</span>
        </div>
        <Link to="/contactus">
          <div
            className="d-flex justify-content-left"
            style={{ textAlign: "left" }}
          >
            <button className="btn btn-outline-light el6-btnpr">
              Get in touch
            </button>
          </div>
        </Link>
      </div>
      <div className="container mt-4">
        <div className="row g-3">
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 ">
                  At Enormous Corporate Solutions, we take pride in our
                  dedicated team of payroll specialists and domain experts who
                  are committed to providing our clients with customized and
                  flexible payroll solutions. We understand that every
                  organization has unique requirements, and we tailor our
                  services to meet their specific needs while maintaining the
                  highest standards of data confidentiality.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2">
                  Our expertise extends to developing customized payroll models
                  for diversified sectors. We approach each project with a focus
                  on transparency, ensuring that our clients have a clear
                  understanding of the processes involved and the compliance
                  standards we adhere to.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2">
                  Whether you are a conglomerate with a network of companies or
                  a single firm, outsourcing your payroll functions can be a
                  strategic decision that allows you to invest more time and
                  resources into managing your core business activities. By
                  partnering with Enormous Corporate Solutions, you gain access
                  to a robust payroll administration system that acts as a
                  catalyst for the transformation you seek.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2">
                  Outsourcing your payroll to an experienced provider like
                  Enormous Corporate Solutions offers several advantages. Our
                  team brings in-depth knowledge and expertise to handle complex
                  payroll processes, ensuring accuracy and efficiency. By
                  entrusting your payroll management to us, you can alleviate
                  the burden on your internal resources and free up valuable
                  time to focus on strategic initiatives that drive growth and
                  success.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2">
                  At Enormous Corporate Solutions, we are committed to
                  delivering exceptional payroll outsourcing services that
                  streamline your operations, mitigate risks, and enhance
                  compliance. Let us be your trusted partner in managing your
                  payroll processes, allowing you to reap the benefits of
                  improved efficiency and cost savings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row g-3">
          <div className="col-12 mb-1 d-flex">
            <div className=" p-1 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2 mb-4 el6-head2">
                  Our Payrolling services offer a range of key highlights
                  designed to simplify and streamline your workforce management:
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row g-3">
          <div className="col-12 col-md-6 col-lg-4 d-flex">
            <div className="card shadow-lg border-light rounded-3 p-3 flex-fill el6-bc">
              <div className="card-body text-center">
                <h3 className="card-title mb-4 el6-head">Staff on Our Rolls</h3>
                <p className="card-text el6-txt">
                  When you utilize our Payrolling services, your staff members
                  will remain on our rolls. This means that we take care of the
                  administrative tasks associated with hiring, onboarding, and
                  managing your employees, while you maintain operational
                  control and direction.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 d-flex">
            <div className="card shadow-lg border-light rounded-3 p-3 flex-fill el6-bc">
              <div className="card-body text-center">
                <h3 className="card-title mb-4 el6-head">
                  {" "}
                  Standard Contractual Techniques
                </h3>
                <p className="card-text el6-txt">
                  Our experienced team is well-versed in handling standard
                  contractual techniques. We ensure that all necessary
                  employment contracts are prepared accurately and in compliance
                  with relevant labor laws and regulations. By entrusting us
                  with this responsibility, you can have peace of mind knowing
                  that your contractual obligations are met efficiently.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12  col-lg-4 d-flex">
            <div className="card shadow-lg border-light rounded-3 p-3 flex-fill el6-bc">
              <div className="card-body text-center">
                <h3 className="card-title mb-4 el6-head">
                  Payroll and Personnel Management
                </h3>
                <p className="card-text el6-txt">
                  Our Payrolling services encompass comprehensive payroll and
                  personnel management. We handle the entire payroll process,
                  including accurate computation of wages, tax deductions, and
                  timely disbursement of salaries. Additionally, we manage
                  personnel records, ensuring that all necessary documentation
                  is maintained and updated regularly.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6 d-flex">
            <div className="card shadow-lg border-light rounded-3 p-3 flex-fill el6-bc">
              <div className="card-body text-center">
                <h3 className="card-title mb-4 el6-head">
                  Statutory Compliance
                </h3>
                <p className="card-text el6-txt">
                  Compliance with statutory requirements is of utmost
                  importance, and our Payrolling services are designed to ensure
                  full adherence. We take care of all aspects related to
                  statutory compliance, including registration of staff members
                  under schemes such as the Employee State Insurance Corporation
                  (ESIC) and Employees' Provident Fund (EPF). We stay up to date
                  with the latest regulations, making certain that your
                  organization meets all statutory obligations.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6 d-flex">
            <div className="card shadow-lg border-light rounded-3 p-3 flex-fill el6-bc">
              <div className="card-body text-center">
                <h3 className="card-title mb-4 el6-head">
                  Settlement of Statutory Payments and Administration
                </h3>
                <p className="card-text el6-txt">
                  In addition to compliance, we handle the settlement of
                  statutory payments and related administrative tasks. This
                  includes accurately calculating and remitting contributions to
                  ESIC and EPF on behalf of your employees. Our dedicated team
                  manages the administrative processes associated with these
                  schemes, allowing you to focus on other critical aspects of
                  your business.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3 d-flex">
            <div className=" p-3 flex-fill">
              <div className="card-body text-center">
                <p className="card-text el6-txt2">
                  With our Payrolling services, you can trust that your
                  workforce management is in capable hands. We handle the
                  intricate tasks of managing staff on our rolls, ensuring
                  compliance with contractual obligations, handling payroll and
                  personnel management, and taking care of statutory compliance
                  and administration. This comprehensive approach allows you to
                  offload administrative burdens and focus on driving your
                  business forward.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 mb-3 d-flex">
            <div className=" p-3 flex-fill">
              <div className="card-body text-center">
                <h4 className="card-title mb-4 el6-head2">Contact Us Now!</h4>
                <p className="card-text el6-txt2">
                  Ready to streamline your payroll processes?
                  <Link to="/contactus" className="el6-dectx">
                    {" "}
                    Contact us{" "}
                  </Link>
                  today to learn how Enormous Corporate Solutions can provide
                  you with reliable and efficient payroll management services.
                  Let us handle your payroll, so you can focus on growing your
                  business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PayrollServices;
