import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './BussV.css';
import Agri from './BVimages/Agri2.jpeg';
import Footer from "../FooterF";
import { Link } from "react-router-dom";

const Agriculture = () => {
    return (
        <div>
            <div className="div1card_el7">
                <div className="image-container img_el7">
                    <img src={Agri} className="img-fluid" alt="Technology" />
                    <div className="overlay_el7"></div>
                    <div className="txt_el7" style={{ textAlign: 'left' }}>
                        <h1 className='text-left txt_head_el7'>Agriculture</h1>
                        {/* <p className='text-left text_slogan_el7'>Pioneering Tomorrow's Solutions Today</p> */}
                        <Link to='/contactus' style={{textDecoration:'none'}}>
                        <div className='d-flex justify-content-left' style={{ textAlign: 'left' }}>
                            <button className="btn btn-outline-light btn_el7">Get in touch</button>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="mlr_el7 d-flex justify-content-center text-center">
                <p className="intro_el7" style={{marginBottom:'0px'}}>
                The agriculture industry demands expertise in crop management, sustainability, and the efficient use of resources. At Enormous Corporate Solutions, we offer specialized staffing solutions to meet the unique needs of the agriculture sector, from farm management to supply chain logistics. Our expertise ensures that you have the right talent to enhance productivity and promote sustainable practices.</p>
            </div>
            <div className="why-choose_el7 ymlr-el7">
                <h2 className='h2b_el7 hbt_el7'>Why Choose Us for Agriculture Staffing?</h2>
                <div>
                    <ul>
                        <li className='ullikk_el7'><strong>Expertise in Agronomy:</strong> We connect agricultural companies with professionals experienced in crop production, soil management, and sustainable farming techniques to improve your yields.</li>
                        <li className='ullikk_el7'><strong>Sector-Specific Knowledge:</strong> Our team understands the complexities of the agriculture industry, ensuring that candidates are well-equipped to handle industry-specific challenges.</li>
                        <li className='ullikk_el7'><strong>Sustainability-Focused:</strong> We ensure that all candidates prioritize sustainable practices, helping you contribute to environmental stewardship and resource conservation.</li>
                        <li className='ullikk_el7'><strong>Efficient Workforce Deployment:</strong> Quick response times for staffing needs to support seasonal operations and ongoing projects.</li>                        
                        <li className='ullikk_el7'><strong>Continual Support:</strong> Ongoing assistance to ensure that team members integrate seamlessly into your organization and drive operational success.</li> 
                    </ul>
                </div>
                <h2 className='h2b_el7 hbt_el7'>Commitment</h2>
                <p className='intro_el7'>
                We are committed to supporting agricultural companies with expert talent that enhances productivity and promotes sustainability. Our staffing solutions are designed to ensure your operations align with industry best practices and contribute to long-term success.</p>
                <h2 className='h2b_el7 hbt_el7'>Contact Us Now!</h2>
                <p className='intro_el7'>Need specialized staffing for the agriculture industry?  {" "}<Link to='/contactus' className="txdec_el7">Contact us</Link> {" "} today to discuss your requirements and find the right experts for your needs.


                </p>
            </div>
            <Footer />
        </div>
    );
}

export default Agriculture;
