import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './BussV.css';
import fmcg from './BVimages/FMCG.jpeg';
import Footer from "../FooterF";
import { Link } from "react-router-dom";

const FMCG = () => {
    return (
        <div>
            <div className="div1card_el7">
                <div className="image-container img_el7">
                    <img src={fmcg} className="img-fluid" alt="Technology" />
                    <div className="overlay_el7"></div>
                    <div className="txt_el7" style={{ textAlign: 'left' }}>
                        <h1 className='text-left txt_head_el7'>FMCG/FMCD</h1>
                        {/* <p className='text-left text_slogan_el7'>Pioneering Tomorrow's Solutions Today</p> */}
                        <Link to='/contactus' style={{textDecoration:'none'}}>
                        <div className='d-flex justify-content-left' style={{ textAlign: 'left' }}>
                            <button className="btn btn-outline-light btn_el7">Get in touch</button>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="mlr_el7 d-flex justify-content-center text-center">
                <p className="intro_el7" style={{marginBottom:'0px'}}>
                The fast-paced FMCG/FMCD industry requires agility, innovation, and a deep understanding of consumer behaviour. At Enormous Corporate Solutions, we offer specialized staffing solutions to address the unique needs of FMCG/FMCD companies, from product development to market launch. 
                Our expertise ensures that you have the right talent to drive growth and maintain competitive advantage in this dynamic sector.    </p>      
                  </div>
            <div className="why-choose_el7 ymlr-el7">
                <h2 className='h2b_el7 hbt_el7'>Why Choose Us for FMCG/FMCD Staffing?</h2>
                <div>
                    <ul>
                        <li className='ullikk_el7'><strong>Specialized Talent:</strong> We connect FMCG/FMCD companies with professionals skilled in product development, supply chain management, marketing, and sales to drive your business forward.</li>
                        <li className='ullikk_el7'><strong>Industry Knowledge:</strong> Our team understands the intricacies of the FMCG/FMCD sector, ensuring that candidates are well-prepared to meet industry-specific demands.</li>
                        <li className='ullikk_el7'><strong>Consumer-Centric Approach:</strong> We ensure that all candidates have a deep understanding of consumer needs, helping you stay ahead in a competitive marketplace.</li>
                        <li className='ullikk_el7'><strong>Rapid Deployment:</strong> Quick turnaround times for staffing needs to support ongoing projects and operational demands.</li>                        
                        <li className='ullikk_el7'><strong>Dedicated Support:</strong> Ongoing support to ensure that candidates fit seamlessly into your organizational culture and project requirements.</li> 
                    </ul>
                </div>
                <h2 className='h2b_el7 hbt_el7'>Commitment</h2>
                <p className='intro_el7'>
                We are committed to supporting FMCG/FMCD companies with expert talent that drives innovation and meets market demands. Our staffing solutions are designed to propel success and ensure your operations are aligned with industry best practices.</p>
                <h2 className='h2b_el7 hbt_el7'>Contact Us Now!</h2>
                <p className='intro_el7'> Need specialized staffing for the FMCG/FMCD industry?  {" "}<Link to='/contactus' className="txdec_el7">Contact us</Link> {" "} today to discuss your requirements and find the right experts for your needs.
                
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default FMCG;
