import React from 'react';
import './Non-ITServices.css'
import NonIT from './images/NonIT.jpeg'
import { Link } from 'react-router-dom';
import Footer from "./FooterF";

const Nonit = () => {
return (
    <div >
        <div className="bg ">
            <div className="body">

                {/* <div >
                    <div className="txtst">
                        <h2 >Oracle Cloud Applications</h2>
                        <p >Transform & Thrive</p>
                        <div>
                            <button type="button" className="btn btn-outline-light w-auto">Schedule a Free Consultation"</button>
                        </div>
                    </div>
                </div> */}
                {/* <div className="image-container">
                    <img src={NonIT} className="img-fluid image" alt="Loading" />
                    <div className="overlay"></div>
                    <div className="txt" style={{ textAlign: 'center' }}>
                        <h2 className='text-xl text-xl-left text-left'>Non IT Services</h2>
                        <div className="d-flex justify-content-center">
                    </div>
                    </div>
                </div> */}
                <div className="div1card_el7">
                <div className="image-container img_el7">
                    <img src={NonIT} className="img-fluid" alt="Technology" />
                    <div className="overlay_el7"></div>
                    <div className="txt_el7" style={{ textAlign: 'center' }}>
                        <h1 className='text-left txt_head_el7'>Our Services</h1>
                        {/* <p className='text-left text_slogan_el7'>Pioneering Tomorrow's Solutions Today</p> */}
                        <Link to='/contactus' style={{textDecoration:'none'}}><div className='d-flex justify-content-center' style={{ textAlign: 'center' }}>
                            <button className="btn btn-outline-light btn_el7">Get in touch</button>
                        </div></Link> 
                    </div>
                </div>
            </div>
            </div>

        </div>

        <div className="mars">

            <div >
                <p className="parastella">
                True to our standing as a prominent Staffing firm, our professional services are 
                 consistently evolving and adjusting to cater to the demands of our ever-changing 
                  world. 
 
                </p>
                <p className="parastella" >
                Our unwavering commitment is to provide HR services and staff of exceptional value 
                 and quality for every open position. Enormous Corporate Solutions offers an extensive 
                  range of services that are meticulously tailored to fulfill the unique requirements of 
                   each client, taking into consideration their work culture, schedule, budget, and 
                    specific needs. 
                </p>
                <p className="parastella" >
                Our dedicated staffing professionals work closely with you to identify and retain top- 
                tier employees, resulting in reduced turnover rates and improved financial outcomes. 
                 Our comprehensive services include:
 
                </p>
            </div>

            <div className="oimgaj">
                {/* <img src={ocSub} alt="Loading" /> */}
            </div>
            <div className="padgs-cloud" >
                <div className="display flex">

                    <div className="cardst display dirssocs">
                        <div className="fontszocs">
                            <p>Permanent / Contract Staffing</p>
                        </div>
                      
                        <Link to='/general-staffing' className='tdaj'><span className='readtxtaj tdaj'>Learn More {'>>>'}</span></Link>
                    </div>


                    <div className="cardst display dirssocs">
                        <div className="fontszocs">
                            <p>Payroll Services</p>
                        </div>
                        
                        <Link to='/payroll-services' className='tdaj'><span className='readtxtaj tdaj'>Learn More {'>>>'}</span></Link>
                    </div>
                </div>

                <div className="display flex">

                    <div className="cardst display dirssocs">
                        <div className="fontszocs">
                            <p>Human Resources Information System (HRIS)</p>
                        </div>
                        
                        <Link to='/hris' className='tdaj'><span className='readtxtaj tdaj'>Learn More {'>>>'}</span></Link>
                    </div>


                    <div className="cardst display dirssocs">
                        <div className="fontszocs">
                            <p>Compliance Management</p>
                        </div>
                        <Link to='/compliance-management' className='tdaj'><span className='readtxtaj tdaj'>Learn More {'>>>'}</span></Link>
                    </div>

                   

                </div>

            </div>


            <div>
                <p className="parastella" >
                {/* <Link to='/contactus' className='tdclkk' >Contact us</Link>{" "} today to discover how Enormous IT's Oracle Managed Services can optimize and secure your Oracle systems. Let us handle the complexities of Oracle management so you can concentrate on driving your business forward with confidence, knowing your Oracle infrastructure is in expert hands. With our dedicated team and proactive support, you'll experience enhanced performance, reduced downtime, and seamless operations, empowering your organization to achieve its goals more effectively.</p> */}
                </p>
            </div>

            <div>
                <p className="parastella">
                In recognition of the diverse staffing requirements across various sectors, we have 
                 developed teams with sector-wise expertise. We understand that the skillsets needed 
                for each sector differ significantly. This expertise allows us to identify and select the right 
                 contractors who align perfectly with the specific needs of our clients based on their 
                 sector. 
                </p>
                <p className="parastella" >
                Whether it's healthcare, finance, engineering, or any other industry, our in-depth sector 
                 knowledge ensures that we provide contractors who possess the specialized skills and 
                  experience required to excel in their respective fields. By leveraging our sector-wise 
                   staffing expertise, we can deliver tailored solutions that meet the unique demands of 
                    clients across all sectors.
 
                </p>
               
            </div>
        </div>


        <Footer />

    </div>

)
}
export default Nonit;