import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

// import Footer from './Footer';
// import Healthcare from './BussVerticals/Healthcare';
// import Career from './Career';
// import Navbar1 from './Navbar';
import Routing from './Routing2';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Routing />
   

);


reportWebVitals();
