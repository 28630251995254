import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ComplianceMngt.css';
import { Link } from "react-router-dom";
import cmpimg from './images/compliance4.png'
import Footer from "./FooterF";

const ComplianceMngt = () => {
    return (
        <div>
            <div className="div1card_el7">
                <div className="image-container img_el7">
                    <img src={cmpimg} className="img-fluid" alt="Technology" />
                    <div className="overlay_el7"></div>
                    <div className="txt_el7" style={{ textAlign: 'left' }}>
                        <h1 className='text-left txt_head_el7'>Compliance Management</h1>
                        {/* <p className='text-left text_slogan_el7'>Pioneering Tomorrow's Solutions Today</p> */}
                        <Link to='/contactus' style={{textDecoration:'none'}}><div className='d-flex justify-content-left' style={{ textAlign: 'left' }}>
                            <button className="btn btn-outline-light btn_el7">Get in touch</button>
                        </div></Link>
                    </div>
                </div>
            </div>
            <div className="mlr_el7">
                <p className="intro_el7" style={{marginBottom:'0px'}}>
                Our comprehensive compliance management services encompass various areas, including shops and establishment compliance, factory compliance, contractor and contract labour compliance, payroll compliance, employer and employee compliance advisory, statutory benefits administration, remittance of statutory payments, and liaison with government authorities. We handle all aspects of statutory compliance, from registration to inspections and exemptions, keeping businesses up to date with industry knowledge and ensuring adherence to regulations.</p>
            </div>
            <div className="mlr_el7">
                <p className="intro_el7" style={{marginBottom:'0px'}}>
                Our dedicated team provides peace of mind by managing labour-related compliance needs and simplifying payroll responsibilities with technology-backed solutions. We review labour practices, advise on regulatory changes, and administer statutory benefits while reducing administrative complexities. With our strong network and ethical approach, we coordinate with government authorities, allowing businesses to focus on their core operations.</p>
            </div>

            <div className="why-choose_el64 ymlr-el64">
            <h2 className='h2b_el64 hbt_el64'>Our Expertise</h2>
     <div className="expertise-content">
        <p><strong>Shops & Establishment Compliance Management:</strong> We provide comprehensive support for managing all statutory compliance aspects related to shops and establishments. From facilitating establishment registration to conducting regular inspections, monitoring exemptions, and ensuring smooth operations, our team meticulously handles every compliance requirement.</p>
        <p><strong>Factory Compliance Management:</strong> Our services extend to supporting routine processes in factory compliance. We keep you informed with the latest industry knowledge, monitor technology, machinery, and processes, and ensure adherence to relevant regulations, allowing you to concentrate on your core operations.</p>
        <p><strong>Contractor & Contract Labour Compliance Management:</strong> Our proactive Legal Compliance Management team offers a complete solution for all your labour-related compliance needs. Whether dealing with direct or indirect employees, we manage the entire spectrum of compliance requirements, giving you peace of mind.</p>
        <p><strong>Payroll Compliance Management:</strong> Avoid the complexities of extensive payroll laws by leveraging our technology-driven payroll compliance solutions. We streamline payroll management, ensuring compliance and operational efficiency.</p>
        <p><strong>Employer and Employee Compliance Advisory Services:</strong> Our seasoned compliance professionals review your labour practices and procedures, ensuring adherence to local employment laws and keeping you updated on regulatory changes. Navigate compliance with ease and confidence through our advisory services.</p>
        <p><strong>Statutory Benefits Administration:</strong> Statutory benefits such as PF, Bonus, OT, and Welfare funds undergo annual changes. Our services assist in administering compliance with the latest amendments, simplifying the complexities of statutory requirements.</p>
        <p><strong>Remittance of Statutory Payments:</strong> Our skilled compliance management team facilitates the smooth remittance of statutory payments, including PF, Employee Insurance, Professional Tax, Labour Fund, Salary, and other relevant payments within your industry, ensuring compliance while minimizing administrative burdens.</p>
        <p><strong>Liaison with Government Authorities:</strong> With our strong ethical ties to both Central and State government authorities, we have established a robust network. This enables us to handle legal coordination on your behalf, so you can focus on your core competencies while we manage effective communication and coordination with the relevant authorities.</p>
    </div>
    </div>
    <div className="col-12 mb-3 m-3">
            <div className=" p-3 flex-fill">
              <div className="card-body text-center">
                <h4 className="card-title mb-4 el6-head2">Contact Us Now!</h4>
                <p className="card-text el6-txt2">
                  Partner with Enormous Corporate Solutions for all your compliance management needs. Our expert team is ready to help you navigate complex regulations and streamline your operations. <Link to="/contactus" className="el6-dectx"> Contact us </Link> to discover how we can simplify compliance, reduce administrative burdens, and ensure your business stays fully compliant with all statutory requirements.
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
    );
}

export default ComplianceMngt;
