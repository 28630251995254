import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './BussV.css';
import kpo from './BVimages/KPO.jpeg';
import Footer from "../FooterF";
import { Link } from "react-router-dom";

const KPO = () => {
    return (
        <div>
            <div className="div1card_el7">
                <div className="image-container img_el7">
                    <img src={kpo} className="img-fluid" alt="Technology" />
                    <div className="overlay_el7"></div>
                    <div className="txt_el7" style={{ textAlign: 'left' }}>
                        <h1 className='text-left txt_head_el7'>KPO/BPO ITES</h1>
                        {/* <p className='text-left text_slogan_el7'>Pioneering Tomorrow's Solutions Today</p> */}
                        <Link to='/contactus' style={{textDecoration:'none'}}>
                        <div className='d-flex justify-content-left' style={{ textAlign: 'left' }}>
                            <button className="btn btn-outline-light btn_el7">Get in touch</button>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="mlr_el7 d-flex justify-content-center text-center">
                <p className="intro_el7" style={{marginBottom:'0px'}}>
                The KPO (Knowledge Process Outsourcing), BPO (Business Process Outsourcing), and ITES (Information Technology Enabled Services) industries are dynamic sectors that require a diverse range of specialized skills to meet the demands of global operations. These industries thrive on efficiency, precision, and adaptability, making it essential to have the right talent in place. At Enormous Corporate Solutions, we offer comprehensive staffing solutions tailored to the unique requirements of the KPO/BPO ITES sectors. Our expertise ensures that your organization is supported by highly skilled professionals who are ready to drive your business forward.</p>
            </div>
            <div className="why-choose_el7 ymlr-el7">
                <h2 className='h2b_el7 hbt_el7'>Why Choose Us for KPO/BPO ITES Staffing?</h2>
                <div>
                    <ul>
                        <li className='ullikk_el7'><strong>Specialized Talent:</strong> We connect organizations with professionals across various functions essential to KPO/BPO ITES operations, including customer service, process management, data handling, and more.</li>
                        <li className='ullikk_el7'><strong>Industry Expertise:</strong> Our team has a deep understanding of the KPO/BPO ITES sector, ensuring that we provide candidates who are equipped to handle the unique challenges and demands of the industry.</li>
                        <li className='ullikk_el7'><strong>Operational Excellence:</strong> We focus on delivering candidates who contribute to the efficiency and effectiveness of your operations, enhancing productivity and service quality.</li>
                        <li className='ullikk_el7'><strong>Rapid Deployment:</strong> Our efficient staffing process ensures quick turnaround times, helping you address immediate staffing needs and maintain operational continuity.</li>                        
                        <li className='ullikk_el7'><strong>Ongoing Support:</strong> We provide continuous support to ensure that new hires seamlessly integrate into your organization and meet your specific requirements.</li> 
                    </ul>
                </div>
                <h2 className='h2b_el7 hbt_el7'>Commitment</h2>
                <p className='intro_el7'>
                At Enormous Corporate Solutions, our commitment to excellence drives everything we do. We are dedicated to empowering KPO/BPO ITES companies by providing them with exceptional talent that not only meets but exceeds industry standards. Our staffing solutions are meticulously crafted to support your business objectives, ensuring that you have the right people in place to achieve your goals. With our expertise, you can be confident that you organization is equipped to handle the challenges of the industry and continue to deliver exceptional service to your clients.</p>
                <h2 className='h2b_el7 hbt_el7'>Contact Us Now!</h2>
                <p className='intro_el7'>Are you looking for specialized staffing solutions to drive success in the KPO/BPO ITES industry?                  {" "}<Link to='/contactus' className="txdec_el7">Contact us</Link> {" "}
                today to discuss your staffing needs. Let us help you find the right talent to propel your organization forward.
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default KPO;
