import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './BussV.css';
import Bfsi from './BVimages/BFSI.jpg';
import Footer from "../FooterF";
import { Link } from "react-router-dom";

const BFSI = () => {
    return (
        <div>
            <div className="div1card_el7">
                <div className="image-container img_el7">
                    <img src={Bfsi} className="img-fluid" alt="Technology" />
                    <div className="overlay_el7"></div>
                    <div className="txt_el7" style={{ textAlign: 'left' }}>
                        <h1 className='text-left txt_head_el7'>Banking, Financial Services, and Insurance (BFSI)</h1>
                        {/* <p className='text-left text_slogan_el7'>Pioneering Tomorrow's Solutions Today</p> */}
                        <Link to='/contactus' style={{textDecoration:'none'}}>
                        <div className='d-flex justify-content-left' style={{ textAlign: 'left' }}>
                            <button className="btn btn-outline-light btn_el7">Get in touch</button>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="mlr_el7 d-flex justify-content-center text-center">
                <p className="intro_el7" style={{marginBottom:'0px'}}>
                The Banking, Financial Services, and Insurance (BFSI) industry demands precision, security, and a deep understanding of financial regulations. At Enormous Corporate Solutions, we offer specialized staffing solutions to address the unique needs of the BFSI sector, from risk management to customer service. Our expertise ensures that you have the right talent to drive financial performance and maintain compliance with regulatory standards.</p>
            </div>
            <div className="why-choose_el7 ymlr-el7">
                <h2 className='h2b_el7 hbt_el7'>Why Choose Us for BFSI Staffing?</h2>
                <div>
                    <ul>
                        <li className='ullikk_el7'><strong>Financial Expertise:</strong> We connect BFSI companies with professionals skilled in financial analysis, risk management, compliance, and customer relations to strengthen your business operations.</li>
                        <li className='ullikk_el7'><strong>Industry-Specific Knowledge:</strong> Our team is well-versed in the intricacies of the BFSI sector, ensuring that candidates are prepared to navigate industry-specific challenges.</li>
                        <li className='ullikk_el7'><strong>Regulatory Compliance Focus: </strong> We ensure that all team members are knowledgeable about the latest regulations, helping you maintain compliance and protect your organization from potential risks.</li>
                        <li className='ullikk_el7'><strong>Swift Staffing Solutions:</strong> Fast and efficient placement of candidates to meet urgent staffing needs and support ongoing projects.</li>                        
                        <li className='ullikk_el7'><strong>Comprehensive Support:</strong> Continuous assistance to guarantee that new hires integrate smoothly into your organization and contribute to its financial success.</li> 
                    </ul>
                </div>
                <h2 className='h2b_el7 hbt_el7'>Commitment</h2>
                <p className='intro_el7'>
                We are committed to supporting BFSI companies with expert talent that drives financial growth and ensures compliance with industry standards. Our staffing solutions are designed to align with your operational goals and contribute to long-term success.</p>
                <h2 className='h2b_el7 hbt_el7'>Contact Us Now!</h2>
                <p className='intro_el7'>Need specialized staffing for the BFSI industry?  {" "}<Link to='/contactus' className="txdec_el7">Contact us</Link> {" "} today to discuss your requirements and find the right experts for your needs.
                
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default BFSI;
