import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './BussV.css';
import Hosp from './BVimages/hospitality.jpg';
import Footer from "../FooterF";
import { Link } from "react-router-dom";

const Hospitality = () => {
    return (
        <div>
            <div className="div1card_el7">
                <div className="image-container img_el7">
                    <img src={Hosp} className="img-fluid" alt="Technology" />
                    <div className="overlay_el7"></div>
                    <div className="txt_el7" style={{ textAlign: 'left' }}>
                        <h1 className='text-left txt_head_el7'>Hospitality</h1>
                        {/* <p className='text-left text_slogan_el7'>Pioneering Tomorrow's Solutions Today</p> */}
                        <Link to='/contactus' style={{textDecoration:'none'}}>
                        <div className='d-flex justify-content-left' style={{ textAlign: 'left' }}>
                            <button className="btn btn-outline-light btn_el7">Get in touch</button>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="mlr_el7 d-flex justify-content-center text-center">
                <p className="intro_el7" style={{marginBottom:'0px'}}>
                The hospitality industry thrives on exceptional service, guest satisfaction, and seamless operations. 
                At Enormous Corporate Solutions, we offer specialized staffing solutions to meet the unique demands of the hospitality sector, 
                from front-of-house to back-end operations. Our expertise ensures that you have the right talent to enhance guest experiences 
                and maintain operational excellence.</p>
            </div>
            <div className="why-choose_el7 ymlr-el7">
                <h2 className='h2b_el7 hbt_el7'>Why Choose Us for Hospitality Staffing?</h2>
                <div>
                    <ul>
                        <li className='ullikk_el7'><strong>Expert Personnel:</strong> We connect hospitality companies with professionals experienced in front-desk operations, culinary management, accommodation services, and event coordination to enhance your service quality.</li>
                        <li className='ullikk_el7'><strong> Sector Insight:</strong> Our team has deep knowledge of the hospitality industry, ensuring that candidates are well-prepared to tackle sector-specific challenges.</li>
                        <li className='ullikk_el7'><strong>Guest-Focused Approach:</strong> We ensure that all team members prioritize guest satisfaction, aiding in the creation of a loyal clientele.</li>
                        <li className='ullikk_el7'><strong>Swift Onboarding:</strong> Fast response times for staffing requirements to support your day-to-day operations and special events.</li>                        
                        <li className='ullikk_el7'><strong>Continuous Assistance:</strong> Ongoing support to guarantee that team members blend smoothly into your organization and elevate the guest experience.</li> 
                    </ul>
                </div>
                <h2 className='h2b_el7 hbt_el7'>Commitment</h2>
                <p className='intro_el7'>
                We are committed to supporting hospitality companies with expert talent that enhances guest experiences and drives operational success. 
                Our staffing solutions are designed to ensure your operations run smoothly and exceed industry standards.</p>
                <h2 className='h2b_el7 hbt_el7'>Contact Us Now!</h2>
                <p className='intro_el7'>Need specialized staffing for the hospitality industry? 
                {" "}<Link to='/contactus' className="txdec_el7">Contact us</Link> {" "} today to discuss your requirements and find the right experts for your needs.
                
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default Hospitality;
