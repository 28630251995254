import "./Aboutus.css";
import About from "./images/Aboutus.jpg";
import midimg from "./images/middleimageabt.jpeg";
import lastimg from "./images/lastimgabt.jpeg";
import Footer from "./FooterF";

const Aboutus = () => {
  return (
    <div>
      <div className="image-container">
        <img className="img_el4" src={About} alt="Loading" />
        <div className="overlay"></div>
        <div className="dflex_el4">
          <p className="txsz_el4">About Us</p>
        </div>
      </div>
      <div>{/* <p className="sz_el4 dflex_el4">Our Journey</p> */}</div>
      <div>
        <p className="dflex_el4 psz_el4">
          Welcome to Enormous Corporate Solutions, your trusted partner for
          comprehensive Non-IT services. We are dedicated to empowering
          businesses of all sizes to thrive in the ever-evolving digital
          landscape through our innovative and customized solutions. As a
          leading service provider, we recognize the critical role that human
          resources play in the success of any organization.
        </p>
      </div>
      <div>{/* <p className="sz_el4 dflex_el4">Our Expansion</p> */}</div>
      <div>
        <p className="dflex_el4 psz_el4">
          We understand that having the right talent and an optimized workforce
          is essential for achieving your business goals. To assist you in this
          endeavor, we offer a range of comprehensive services designed to
          streamline and optimize your workforce, e nsuring that you have the
          right people in the right roles. In addition to our HR services, we
          specialize in delivering cutting-edge Non-IT solutions that allow you
          to leverage technology for a strategic advantage.
        </p>
        <p className="dflex_el4 psz_el4">
          Our solutions cover a broad spectrum of areas, aligning your
          requirements with your business objectives to drive growth and
          efficiency. Our services are tailored to provide expertise that is
          responsive to your specific business needs. Whether you require
          ongoing Non IT support or specialized Non IT projects, we have you
          covered. Our Managed Services Model allows you to entrust your
          infrastructure and operations to us, freeing up your internal
          resources to focus on core business activities. With our Co-Managed
          Services, we work collaboratively with your in-house team to enhance
          capabilities and provide comprehensive Non-IT support.
        </p>
        <p className="dflex_el4 psz_el4">
          Our Staffing Solutions Services assists you in finding the right
          talent for your organization, both on a temporary and permanent basis.
          We also provide Payroll services to streamline your payroll processes
          and ensure accurate and timely compensation for your employees. With
          our Outsourcing services, you can delegate specific business functions
          to our experts, allowing you to focus on your core competencies.
        </p>
      </div>
      <div className="dflex_el4">
        <img className="midimg_el4 " src={midimg} alt="Loading" />
      </div>
      <div>
        <p className="dflex_el4 psz_el4">
          Our Statutory Compliance services ensure that your business remains
          compliant with relevant regulations and laws. We can also help you
          implement HRIS (Human Resources Information System) solutions to
          automate and streamline your HR processes. Additionally, our Contract
          Labour services enable you to effectively manage your contract
          workforce, ensuring compliance and efficiency.
        </p>
      </div>
      <div>
        <p className="dflex_el4 psz_el4">
          At Enormous Corporate Solutions, we pride ourselves on delivering
          comprehensive Non-IT solutions that address the unique needs of your
          business. Our team of experienced professionals is passionate about
          helping you achieve your goals and succeed in the digital landscape.
          Whether it's optimizing your workforce, leveraging technology, or
          ensuring compliance, we are committed to providing you with
          exceptional service and delivering results that drive your business
          forward.
        </p>
      </div>
      <div className="cardimgtext3_el4">
      <div className="d-flex justify-content-center align-item-center" style={{display:'flex', flexDirection:'column'}}>
      <div className="cardimgtext2_el4 card-container cardscont_el4 d-flex justify-content-center align-item-center" >
      <div >
        <p className="sz_el4 dflex_el4" >Vision</p>
      </div>
      <div>
        <p className="dflex_el4 psz_el4">
          To be the leading provider of innovative and customized Non-IT
          solutions, empowering businesses to thrive in the ever-evolving
          digital landscape.
        </p>
      </div>
      </div>
      <div className="cardimgtext2_el4 card-container cardscont_el4 d-flex justify-content-center align-item-center">
      <div>
        <p className="sz_el4 dflex_el4" >Mission</p>
      </div>
      <div>
        <p className="dflex_el4 psz_el4">
          Our mission is to optimize workforce efficiency, align technology with
          business objectives, and deliver exceptional services that drive
          growth and success for our clients.
        </p>
        </div>
      </div>
      </div>
      <div className="cardimgtext2_el4 d-flex justify-content-center align-item-center" >
      <div>
        <p className="sz_el4 dflex_el4">Our Values</p>
      </div>
      <div>
        <p className="dflex_el4 sz1_el4">Excellence, Integrity, and Trust: </p>
        <p className="dflex_el4 psz_el4">
          We deliver exceptional quality, innovation, and results while
          upholding the highest standards of integrity, honesty, and ethical
          conduct.{" "}
        </p>
        <p className="dflex_el4 sz1_el4">
          Customer-Centricity and Collaboration:
        </p>
        <p className="dflex_el4 psz_el4 ">
          {" "}
          We prioritize our clients' needs, foster collaboration, and provide
          tailored solutions that exceed expectations.{" "}
        </p>
        <p className="dflex_el4 sz1_el4" >
          Continuous Improvement and Accountability:{" "}
        </p>
        <p className="dflex_el4 psz_el4">
          We embrace a culture of continuous improvement, taking ownership of
          our actions and ensuring the achievement of our clients' goals.{" "}
        </p>
        <p className="dflex_el4 sz1_el4">People Focus:</p>
        <p className="dflex_el4 psz_el4">
          We value our people, invest in their growth, and cultivate a
          supportive environment where they can thrive and reach their full
          potential.
        </p>
      </div>
</div>
</div>
      <div className="overlap-card-container">
        <img src={lastimg} alt="Overlapping" className="image_el4 " />
        <div className="cardimgtext_el4">
          <p className="cardimgtext1_el4">
            Partner with us to unlock the full potential of your organization.
            Contact Enormous Corporate Solutions today, and let us be your
            trusted partner in navigating the digital landscape and achieving
            sustainable success.
          </p>
          <div>
            <button className="button_el4">Contact Us Now!</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Aboutus;
